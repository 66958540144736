import { graphql, useStaticQuery } from 'gatsby'

export const useCaseStudiesPopup = () => {
  const { content } = useStaticQuery(graphql`
    query {
      content: allContentfulMxContainer(
        filter: { contentful_id: { eq: "1y2CeKYzSREhvIcgz26Q7y" } }
      ) {
        data: edges {
          node {
            sectionModules {
              moduleTitle
              moduleMisc {
                description
                action {
                  text
                }
              }
              images {
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED, width: 1000)
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  return content
}
